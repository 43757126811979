import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  breadListState: [], // 面包屑列表数据
  currentScreenWidth: '', // 当前页面的宽度
  userFollowed: [], // 用户follow考试信息
}


export default new Vuex.Store({
  state,
  mutations: {
    breadListMutations (state, list) {
      state.breadListState = list
    },
    screenWidthChange (state, width) {
      state.currentScreenWidth = width
    },
    initFollowed (state, list){
      state.userFollowed = list
    }
  }
})
